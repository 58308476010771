<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        autocomplete="off"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              name="Kullanıcı Adı"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Kullanıcı Adı">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="kullanici_adi"
                  v-model="form.kullanici_adi"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              name="Şifre"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Şifre">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="sifre"
                  type="password"
                  v-model="form.sifre"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              name="Port"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Port">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="port"
                  v-model="form.port"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              name="Host"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label="Host">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="host"
                  v-model="form.host"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label-cols="5" label="Güvenlik ( Secure )">
              <b-form-checkbox
                class="float-right"
                :checked="form.secure"
                v-model="form.secure"
                switch
                size="lg"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="8">
            <div class="d-flex">
              <b-button
                squared
                type="submit"
                size="lg"
                variant="primary"
                class="mr-2"
              >
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? "EKLE" : "GÜNCELLE" }}
              </b-button>

              <b-button
                squared
                size="lg"
                variant="info"
                class="mr-2"
                v-if="form.k_no"
                @click="handlerTestMailiGonder"
              >
                <i class="fad fa-envelope"></i> Test Maili Gonder
              </b-button>

              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import axiosIns from "@/libs/axios";
export default defineComponent({
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.form = ref({
      k_no: null,
      kullanici_adi: null,
      sifre: null,
      port: null,
      host: null,
      secure: false,
    });

    const handlerConfig = async () => {
      context.emit("show", true);
      await store.dispatch("epostaAyarlariGetir").then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit("show", false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("epostaAyarlariEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              expo.form.value._id == null
                ? "Ekleme Başarılı."
                : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            context.emit("show", false);
          }
        })
        .catch((err) => {
          context.emit("show", false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    expo.handlerTestMailiGonder = () => {
      context.emit("show", true);
      axiosIns
        .post("/ayarlar/eposta-test-gonder")
        .then((res) => {
          if (res.data.success) {
            toast.success("Test maili gönderilmiştir.", {
              position: "bottom-left",
            });
            context.emit("show", false);
          } else {
            toast.success(JSON.stringify(res.data.message), {
              position: "bottom-left",
            });
            context.emit("show", false);
          }
        })
        .catch((err) => {
          toast.success(JSON.stringify(err), { position: "bottom-left" });
          context.emit("show", false);
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
